import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    item:{
        width: "100%",
        padding: "20px",
    },
}))

export const DetailItem = () => {
    const classes = useStyles();

    return (
        <div className={classes.item}>
            Prueba
        </div>
    )
}
