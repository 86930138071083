import React from 'react'
import {Typography,
        Container,
        Box
        } from '@material-ui/core'
import { ArrowDownward } from '@material-ui/icons';

function Contact() {

    return (
        <div>
            <Container>
                <Box p={2} m={5}>
                    <Typography variant="h4" align="center">
                        Contactame
                    </Typography>
                    <Typography variant="h3" align="center">
                        <ArrowDownward />
                        <ArrowDownward />
                        <ArrowDownward />
                        <ArrowDownward />
                        <ArrowDownward />
                        <ArrowDownward />
                        <ArrowDownward />
                        <ArrowDownward />
                        <ArrowDownward />
                        <ArrowDownward />
                        <ArrowDownward />
                    </Typography>
                </Box>
            </Container>
        </div>
    )
}

export default Contact;
