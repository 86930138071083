import React from 'react'
import ProyectoRecuadro from './ProyectoRecuadro'
import { 
        Grid,
        makeStyles,
        Container,
        Box,
        Typography
        } from '@material-ui/core'

const useStyles = makeStyles({
    containerProyectos: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10vh",
    },
})

function Proyectos() {
    const classes = useStyles();

    const data = [
        {
            id: 1,
            titulo: "E-commerce",
            imagen: "tienda-ciencia.png",
            descripcion: "Desarollado con ReactJS, Material UI y Firebase",
            github: "https://github.com/manugonzalezm/react-manuelgonzalez",
            sitio: "https://react-ecommerce-manuelgm.web.app/",
        },
        {
            id: 2,
            titulo: "Integración con API",
            imagen: "superhero-team.png",
            descripcion: "Desarollado con React, Bootstrap, Formik, Yup, Axios y 'Superhero API'.",
            github: "https://github.com/manugonzalezm/react_challenge_alkemy",
            sitio: "https://superhero-team-alkemy.web.app/",
        },
        {
            id: 3,
            titulo: "Proyecto real para emprendimiento",
            imagen: "baby-coat.png",
            descripcion: "Desarollado con HTML, CSS (usando también media queries) y Bootstrap 4.",
            github: "https://github.com/manugonzalezm/baby-coat-indumentaria",
            sitio: "https://manugonzalezm.github.io/baby-coat-indumentaria/",
        },
        {
            id: 4,
            titulo: "Sitio Colegio",
            imagen: "argentum.png",
            descripcion: "Desarollado con Bootstrap y Javascript (formulario).",
            github: "https://github.com/manugonzalezm/TP0-GonzalezMouri-oManuel",
            sitio: "https://manugonzalezm.github.io/TP0-GonzalezMouri-oManuel/",
        },
        {
            id: 5,
            titulo: "CRUD Biblioteca",
            imagen: "bibliotech.png",
            descripcion: "Desarollado con Bootstrap, JS, Python y MySQL.",
            github: "https://github.com/manugonzalezm/sistema_gestion_biblioteca",
            sitio: "https://sistemagestionbiblioteca--bibliotecacac.repl.co/",
        },
        {
            id: 6,
            titulo: "Casa de comidas",
            imagen: "trattoria-di-marce.png",
            descripcion: "Desarollado con Sass y Bootstrap.",
            github: "https://github.com/manugonzalezm/Entrega_Proyecto_Final",
            sitio: "https://manugonzalezm.github.io/Entrega_Proyecto_Final/",
        },
    ]

    return (
        <div>
            <Container>
                <Box p={2} m={5}>
                    <Typography variant="h4" align="center">
                        Mis Proyectos
                    </Typography>
                </Box>
            </Container>
            <Grid container spacing={3}>
                {data.map(proyecto=>{
                    return(
                    <Grid item xs={12} sm={6} md={4} lg={4} className={classes.containerProyectos}>
                        <ProyectoRecuadro 
                            key={proyecto.id}
                            id={proyecto.id}
                            titulo={proyecto.titulo}
                            imagen={proyecto.imagen}
                            descripcion={proyecto.descripcion}
                            github={proyecto.github}
                            sitio={proyecto.sitio}
                        />
                    </Grid>)
                })}
            </Grid>
        </div>
    )
}
export default Proyectos;
