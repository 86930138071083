import React, { useEffect } from 'react'
import {makeStyles,
        Typography,
        Button,
        Card,
        CardActionArea,
        CardMedia,
        CardContent,
        CardActions
        } from '@material-ui/core'
import {GitHub,
        Language
        } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    heading: {
        fontSize: "1.4rem",
        fontWeight: "400",
        padding: "0px",
        margin: "0px",
    },
    imagen: {
        width: "100%",
        height: "50%",
    },
    accordion: {
        width: "100%",
    },
    detalle:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    cardActions: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "10px",
    },
    root: {
        width: 360,
        boxShadow: "1px 12px 3px -7px rgba(0,0,0,0.75)",
    },
    media: {
        height: 240,
    },
}))

function ProyectoRecuadro(props) {
    const classes = useStyles();

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return (
      <Card className={classes.root}>
        <a
          href={props.sitio}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={`${process.env.PUBLIC_URL}/assets/images/${props.imagen}`}
              title={props.titulo}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {props.titulo}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.descripcion}
              </Typography>
            </CardContent>
          </CardActionArea>
        </a>
        <CardActions className={classes.cardActions}>
            <a 
              href={props.github}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="medium" color="primary" variant="contained" startIcon={<GitHub />}>
                Código
              </Button>
            </a>
            <a 
              href={props.sitio}
              target="_blank"
              rel="noopener noreferrer"
            >
            <Button size="medium" color="secondary" variant="contained" startIcon={<Language />}>
            Sitio
          </Button>
            </a>
        </CardActions>
      </Card>
    )
}

export default ProyectoRecuadro;
