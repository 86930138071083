import React, { useEffect } from 'react'
import {Typography,
    makeStyles,
    Container,
    Box,
    IconButton
    } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'

const useStyles = makeStyles({
    contenedor: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    cv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "90%",
        border: "black 1px solid",
        borderRadius: "1rem",
        marginBottom: "40px",
    },
    imagenCv: {
        width: "80%",
        height: "80%",
    },
})

function Cv() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Container>
                <Box p={2} m={4}>
                    <Typography variant="h4" align="center">
                        Mi CV 
                        <a 
                            href={`${process.env.PUBLIC_URL}/assets/pdfs/Manuel-Gonzalez-Mouriño-CV.pdf`}
                            download
                        >
                            <IconButton color="primary" variant="contained">
                                <GetApp fontSize="large"/>
                            </IconButton>
                        </a>
                    </Typography>
                        
                    
                </Box>
            </Container>
            <Container  className={classes.contenedor} >
                <Box className={classes.cv} >
                    <img className={classes.imagenCv} src={`${process.env.PUBLIC_URL}/assets/images/cv.png`} alt="Curriculum Vitae" />
                </Box>
            </Container>
        </div>
    )
}

export default Cv
