import React, { useEffect } from 'react'
import {Typography,
        makeStyles,
        Container,
        Box,
        Grid,
        Avatar,
        Accordion,
        AccordionSummary,
        AccordionDetails
        } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles({
    texto: {
        margin: "3vh",
        padding: "3vh",
    },
    aptitud: {
        display: "flex",
        alignItems: "center",
        marginLeft: "50px",
    },
    margen: {
        marginLeft: "5px",
    },
    containerAptitudes: {
        marginBottom: "10px",
        marginTop: "-20px",
    },
    containerCertificado: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    certificado: {
        width: "90%",
        height: "70%",
    },
    accordion: {
        width: "85%",
        backgroundColor: "#c4d8f5",
    },
    containerCertifs: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "50px",
    },
})

function About() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const aptitudes = [
        {
            nombre: "React JS",
            imagen: "react.png",
        },
        {
            nombre: "Redux",
            imagen: "redux.svg",
        },
        {
            nombre: "Material UI",
            imagen: "mui.png",
        },
        {
            nombre: "Sass",
            imagen: "sass.png",
        },
        {
            nombre: "Javascript",
            imagen: "javascript.png",
        },
        {
            nombre: "Python",
            imagen: "python.png",
        },
        {
            nombre: "Django",
            imagen: "django.png",
        },
        {
            nombre: "Flask",
            imagen: "flask.png",
        },
        {
            nombre: "MySQL",
            imagen: "mysql.png",
        },
        {
            nombre: "Git",
            imagen: "git.png",
        },
        {
            nombre: "HTML5",
            imagen: "html5.png",
        },
        {
            nombre: "CSS3",
            imagen: "css3.jpg",
        },
        {
            nombre: "Bootstrap",
            imagen: "bootstrap.png",
        },
        {
            nombre: "Materialize",
            imagen: "materialize.png",
        },
        {
            nombre: "Firebase",
            imagen: "firebase.png",
        },
        {
            nombre: "GitHub",
            imagen: "github.png",
        },
        {
            nombre: "SCRUM",
            imagen: "scrum.jpg",
        },
    ]

    const certificates = [
        {
            id: "idAlkemy",
            nombre: "Aceleración React JS (Alkemy)",
            imagen: "alkemy.png",
            alt: "Aceleración React JS - Alkemy"
        },
        {
            id: "idUniversidad",
            nombre: "1º y 2º año de Ingeniería Civil (UTN)",
            imagen: "universidad.jpg",
            alt: "Certificado materias universidad UTN"
        },
        {
            id: "idPython",
            nombre: "Full Stack Python (CodoACodo)",
            imagen: "cac-python.jpg",
            alt: "Full stack Python"
        },
        {
            id: "idReact",
            nombre: "React JS (CoderHouse)",
            imagen: "react.png",
            alt: "React JS"
        },
        {
            id: "idAccenture",
            nombre: "Soft-Skills (Accenture)",
            imagen: "accenture.jpg",
            alt: "soft skills accenture"
        },
        {
            id: "idJs",
            nombre: "Javascript (CoderHouse)",
            imagen: "javascript.png",
            alt: "Javascript"
        },
        {
            id: "idDesarrollo",
            nombre: "Desarrollo Web (CoderHouse)",
            imagen: "desarrollo.png",
            alt: "Desarrollo Web"
        },
    ]

    return (
        <div>
            <Container className={classes.titulo}>
                <Box p={2} m={3}>
                    <Typography variant="h4" align="center">
                        Aptitudes:
                    </Typography>
                </Box>
            </Container>
            <Container id="aptitudes">
                <Grid container className={classes.containerAptitudes}>
                    {aptitudes.map(item => {
                        return(
                        <Grid item xs={12} sm={6} md={4}>
                            <Box p={1} m={1} className={classes.aptitud}>
                                <Avatar alt={item.nombre} src={`${process.env.PUBLIC_URL}/assets/images/aptitudes/${item.imagen}`}/>
                                <Typography variant="h6" className={classes.margen}>
                                    {item.nombre}
                                </Typography>
                            </Box>
                        </Grid>
                        )
                    })}
                    
                </Grid>
            </Container>
            <Container className={classes.containerCertifs}>
                <Box p={2} m={3}>
                    <Typography variant="h4" align="center">
                        Formación:
                    </Typography>
                </Box>
                {certificates.map(certif => {
                    return(
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls={certif.nombre}
                            id={certif.id}
                            >
                                <Typography variant="h6">{certif.nombre}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.containerCertificado}>
                                <img 
                                    src={`${process.env.PUBLIC_URL}/assets/images/certificates/${certif.imagen}`}
                                    alt={certif.alt}
                                    className={classes.certificado}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                    })}
            </Container>
        </div>
    )
}

export default About;