import React from 'react'
import { Typography, Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    banner: {
        backgroundColor: "#6abcfc",
        color: "black",
    },
})

function Banner() {

    const classes = useStyles();

    return(
        <div>
            <Box className={classes.banner}>
                <Typography variant="h6" align="center" id="tituloBanner">
                    Jr. Front-End Developer
                </Typography>
            </Box>
        </div>
    );
}

export default Banner;
