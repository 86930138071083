import React from 'react'
import ListaResponsive from './ListaResponsive'
import {
        makeStyles,
        Drawer,
        Divider
        }from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 240,
        flexShrink:0,
    },
    drawerPaper: {
        width: 240,
    },
    toolbar: theme.mixins.toolbar,
}))

function DrawerMenu(props) {
    const classes = useStyles();

    return (
        <Drawer 
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
            variant={props.variant}
            open={props.open}
            onClose={props.onClose ? props.onClose : null}
        >
            <div className={classes.toolbar}></div>
            <Divider />
            <ListaResponsive />
        </Drawer>
    )
}

export default DrawerMenu;
