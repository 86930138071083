import React, { useState } from 'react'
import NavBar from './NavBar'
import DrawerMenu from './DrawerMenu'
import Footer from './Footer'
import Home from './Home'
import Proyectos from './proyectos/Proyectos'
import About from './about/About'
import Cv from './cv/Cv'
import Contact from './contact/Contact'
import { DetailContainer } from './detail/DetailContainer'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import {makeStyles,
        Hidden,
        } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
}))

function Contenedor() {
    const classes = useStyles();
    const [abrir, setAbrir] = useState(false)

    const accionAbrir = () => {
        setAbrir(!abrir)
    }

    return(
        <BrowserRouter>
            <div className={classes.root}>
                <NavBar 
                    accionAbrir={accionAbrir}
                />
                <Hidden>
                    <DrawerMenu
                        variant="temporary"
                        open={abrir}
                        onClose={accionAbrir}
                    />
                </Hidden>
                <div className={classes.content}>
                    <div className={classes.toolbar}></div>
                    <Switch>
                        <Route exact path='/' component={ Home } />
                        <Route exact path='/mis-proyectos' component={ Proyectos } />
                        <Route exact path='/detail/:id' component={ DetailContainer } />
                        <Route exact path='/about-me' component={ About } />
                        <Route exact path='/cv' component={ Cv } />
                        <Route exact path='/contact' component={ Contact } />
                    </Switch>
                    <Footer />
                </div>
            </div>
        </BrowserRouter>
    );
}

export default Contenedor;
