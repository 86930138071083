import React from 'react'
import Banner from './Banner'
import { Link } from 'react-router-dom'
import {AppBar, 
        Toolbar, 
        Typography, 
        makeStyles,
        Button,
        Hidden, 
        IconButton } from '@material-ui/core';
import{ Menu } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display:'none',
        },
    },
    title: {
        flexGrow: 1,
    },
}))

function NavBar(props) {
    const classes = useStyles();

    return(
        <div>
            <AppBar position="fixed" color="primary">
                <Toolbar>
                    <IconButton 
                        className={classes.menuButton} 
                        color="inherit" 
                        aria-label="menu"
                        onClick={() => props.accionAbrir()}
                    >
                        <Menu />
                    </IconButton>
                        <Typography 
                        id="titulo"
                        className={classes.title}
                        variant="h4" component="h1">
                            <Link to="/">
                            Manuel Gonzalez Mouriño
                            </Link>
                        </Typography>
                    <Hidden smDown>
                        <Link to="/mis-proyectos">
                            <Button variant="text" size="large" color="inherit">
                                Mis proyectos
                            </Button>
                        </Link>
                        <Link to="/about-me">
                            <Button variant="text" size="large" color="inherit">
                                Aptitudes
                            </Button>
                        </Link>
                        <Link to="/cv">
                            <Button variant="text" size="large" color="inherit">
                                Mi CV
                            </Button>
                        </Link>
                        <Link to="/contact">
                            <Button variant="text" size="large" color="inherit">
                                Contactame
                            </Button>
                        </Link>
                    </Hidden>
                </Toolbar>
                <Banner position="fixed" color="secondary"/>
            </AppBar>
        </div>
    );
}

export default NavBar;