import { createTheme } from '@material-ui/core/styles';
import { blue, amber } from '@material-ui/core/colors'

const theme = createTheme({
    palette: {
        primary: blue,
        secondary: amber,
    }
})

export default theme;