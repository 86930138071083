import React from 'react';
import { Link } from 'react-router-dom'
import {List, 
        ListItem,
        ListItemIcon, 
        ListItemText
        } from '@material-ui/core';
import { Home, Web, ListAlt, ContactMail, Person } from '@material-ui/icons';

function ListaResponsive() {
    return(
        <div>
            <List component="nav">
                <Link to="/">
                    <ListItem button>
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>
                </Link>
                <Link to="/mis-proyectos">
                    <ListItem button>
                        <ListItemIcon>
                            <Web />
                        </ListItemIcon>
                        <ListItemText primary="Mis proyectos" />
                    </ListItem>
                </Link>
                <Link to="/about-me">
                    <ListItem button>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary="Aptitudes" />
                    </ListItem>
                </Link>
                <Link to="/cv">
                    <ListItem button>
                        <ListItemIcon>
                            <ListAlt />
                        </ListItemIcon>
                        <ListItemText primary="Mi CV" />
                    </ListItem>
                </Link>
                <Link to="/contact">
                    <ListItem button>
                        <ListItemIcon>
                            <ContactMail />
                        </ListItemIcon>
                        <ListItemText primary="Contactame" />
                    </ListItem>
                </Link>
            </List>
        </div>
    );
}

export default ListaResponsive;
