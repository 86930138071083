import React, { useEffect } from 'react';
import {
        Container,
        Grid,
        makeStyles,
        Typography
        } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    foto: {
        width: "250px",
        height: "250px",
    },
    container: {
        marginTop: "50px",
        marginBottom: "50px",
    },
    descripcion: {
        padding: "1.6rem",
        margin: "1.6rem",
    },
    containerFoto: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}))

function Home() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <Container className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} className={classes.containerFoto}>
                    <img 
                        src={`${process.env.PUBLIC_URL}/assets/images/fotoCV.png`} 
                        alt="Foto de Manuel Gonzalez Mouriño"
                        className={classes.foto}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography
                        variant="h5"
                    >
                        Hola, soy Manuel. Bienvenidx a mi portfolio
                    </Typography>
                    <Typography
                        className={classes.descripcion}
                    >
                        Soy un joven desarrollador web con un gran espíritu autodidacta y muchísimas ganas de trabajar en equipo, aprendiendo y creciendo profesionalmente en el proceso. Tengo aprobados los primeros 2 años de Ingeniería Civil en la UTN, gracias a lo cual tengo una fuerte base en matemática y lógica. Después de reencontrarme con la programación descubrí que realmente es el lugar en donde puedo ser creativo y encontrar soluciones a todo problema que se presente. Cambié de especialidad a Ingeniería en Informática en UADE (inicié en Marzo 2022) y estoy en búsqueda de empleo como desarrollador para poder aportar todas mis capacidades y conocimientos a una empresa que me dé la oportunidad. Cuento con 4 meses de experiencia profesional en una empresa del rubro.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Home;
