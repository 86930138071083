import React from 'react'
import {
        Grid,
        Box,
        Typography,
        Container,
        makeStyles,
        Divider
        } from '@material-ui/core'
import { LinkedIn, GitHub, Phone, Mail } from '@material-ui/icons';

const useStyles = makeStyles({
    footer: {
        padding: "15px",
    },
})

function Footer() {
    const classes = useStyles();

    return(
        <Container>
            <Divider />
            <Grid container className={classes.footer} id="footer">
                <Grid item xs={12}>
                    <Box>
                        <Typography variant="h6" id="tituloFooter">
                            Contactame
                        </Typography>
                        <Typography variant="h6">
                            <a
                                href="https://wa.me/5491137664874" 
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Phone />  +54 9 11 3766 4874
                            </a>
                        </Typography>
                        <Typography variant="h6">
                            <Mail />  manuelgonzalez4a@gmail.com
                        </Typography>
                        <Typography variant="h6">
                            <a 
                                href="https://www.linkedin.com/in/manuelgonzalezmourino/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LinkedIn /> /manuelgonzalezmourino
                            </a>
                        </Typography>
                        <Typography variant="h6">
                            <a 
                                href="https://github.com/manugonzalezm"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <GitHub /> /manugonzalezm
                            </a>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Typography variant="h6" align="right">By Manuel Gonzalez Mouriño</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Footer;
