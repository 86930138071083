import React, { useEffect } from 'react';
import { DetailItem } from './DetailItem';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
/* import db from '../../firebase'; */
/* import { collection, doc, getDoc } from 'firebase/firestore'; */

const useStyles = makeStyles(theme => ({
    container:{
        margin: "6% auto",
        backgroundColor: "red",
        width: "78%",
    },

}))

export const DetailContainer = () => {
    const classes = useStyles();
    /* const [detail, setDetail] = useState([]); */
    /* const [loading, setLoading] = useState(true); */
    const {id} = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        /* const obtenerDatos = async() => {
            const docRef = doc(db, "proyectos", id);
            const docSnap = await getDoc(docRef)
console.log("Hola")
            if (docSnap.exists()) {
                console.log(docSnap.data());
            } else {
                console.log("No se encontró el documento")
            }
        } */

        /* const obtenerDatos = async() => {
            const datos = await getDocs(collection(db, "proyectos"),where('id', '==', id))
            datos.forEach((dato)=>{
                console.log(dato.data())
            })
        } */

        /* obtenerDatos(); */
    }, [/* id */])

    return (
        <div className={classes.container}>
            <DetailItem />
        </div>
    )
}
